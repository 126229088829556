import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import { homeDate } from '../../api/home';
import Navigation from '../common/Navigation.vue';
import Information from '../common/information/Information.vue';
import { Lazyload, Image as VanImage } from 'vant';
Vue.use(Lazyload);
export default {
  name: 'HomePage',
  components: {
    Information,
    Navigation,
    [VanImage.name]: VanImage
  },
  data() {
    return {
      scrollTop: 0,
      // originPrice: Number,
      // salePrice: Number,
      changeLineShow: false,
      banner: '',
      guide: '',
      productList: [],
      selectedIndex: -1 // 用来记录被触摸到的 li 元素
    };
  },
  created: async function () {
    this.getHomePage();
  },
  mounted() {
    // eslint-disable-next-line eqeqeq
    const id = localStorage.getItem('goWhere');
    if (id) {
      // var id = hash.substring(index + 1, hash.length + 1)
      var div = document.getElementById(id);
      if (div) {
        setTimeout(function () {
          div.scrollIntoView();
          localStorage.removeItem('goWhere');
        }, 500);
      }
    }
    const lis = document.querySelectorAll('#productList li');
    for (const li of lis) {
      li.dataset.originalImageUrl = li.querySelector('img').src;
    }
  },
  methods: {
    // onTouchStart(index) {
    //   @touchstart="onTouchStart(index)"
    // :src="index === selectedIndex ? (product.magicImage ? product.magicImage : product.coverImg) : product.coverImg"
    //       @touchend="onTouchEnd"
    //   this.selectedIndex = index
    // },
    // onTouchMove(event) {
    //   if (event.target.closest('li') !== this.touchTarget) {
    //     this.resetAllImages() // 如果触摸移动到其他 li 元素上了，就重置所有图片
    //   }
    // },
    // onTouchEnd() {
    //   this.selectedIndex = -1
    // },
    // resetAllImages() {
    //   const lis = document.querySelectorAll('#productList li')
    //   for (const li of lis) {
    //     li.querySelector('img').src = li.dataset.originalImageUrl // 恢复原始图片路径
    //   }
    // },
    // gtag() {
    //   window.dataLayer = window.dataLayer || []
    //   window.dataLayer.push(arguments)
    // },
    getHomePage() {
      homeDate().then(res => {
        this.banner = res.data.data.banner;
        this.guide = res.data.data.guide;
        this.productList = res.data.data.productList;
        // this.originPrice = res.data.data.productList
      });
    },
    onProductClick(productId) {
      if (productId == 18) {
        window.open('https://www.udefine.shop/products/mini-photo-book', '_self');
      } else {
        this.$router.push({
          path: '/editor/detail',
          query: {
            productId: productId
          }
        });
      }
    },
    onProductClickV2(pageName) {
      const currentDomain = window.location.protocol + '//' + window.location.host;
      window.open(currentDomain + '/products/' + pageName, '_self');
    }
  }
};