export default {
  name: 'Information',
  components: {},
  created: async function () {},
  methods: {
    goToAboutUs() {
      window.location.href = "https://www.udefine.shop/page/aboutus";
      // this.$router.push({
      //   path: '/information/aboutUs'
      // })
    },
    goToPolicy() {
      window.location.href = "https://www.udefine.shop/page/policy";
      // this.$router.push({
      //   path: '/information/policy'
      // })
    },
    goToReturn() {
      window.location.href = "https://www.udefine.shop/page/return";
      // this.$router.push({
      //   path: '/information/return'
      // })
    },
    goToRights() {
      window.location.href = "https://www.udefine.shop/page/rights";
      // this.$router.push({
      //   path: '/information/rights'
      // })
    },
    goToShoppingPolicy() {
      window.location.href = "https://www.udefine.shop/page/shipping-policy";
      // this.$router.push({
      //   path: '/information/shoppingPolicy'
      // })
    },
    goToTerms() {
      window.location.href = "https://www.udefine.shop/page/terms";
      // this.$router.push({
      //   path: '/information/terms'
      // })
    }
  }
};